.App {
  margin: auto;
}

.googleSignIn {
  padding: 0px;
  border: 0px;
  background-color: inherit;
  padding: 0;
  margin: 0;
}

.googleSignIn:hover {
  cursor: pointer;
  opacity: 0.8;
  transform: scale(1.02)
}

.amplify-form-signup {
  display: none;
}

.checkboxes {
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 20px auto;
  padding: 20px 0px;
  justify-content: space-around;
}

.keyAccountInputLabel {
  margin-left: 5px;
}

.keywordFound {
  background-color: lightgreen;
  width: 50%;
  height: 100%;
}

.keywordClosing {
  background-color: #8bbd78;
  width: 50%;
  height: 100%;
}

.keywordBuilding {
  background-color: rgb(247, 189, 83);
  width: 50%;
  height: 100%;
}

.keywordQualifying {
  background-color: lightskyblue;
  width: 50%;
  height: 100%;
}

.keywordNotFound {
  width: 50%;
  height: 100%;
}

.borderRight {
  border-right: 1px dotted lightgray;
}

.colorExplanations {
  margin-top: 30px;
  display: flex;
}

.column-explanation {
  display: flex;
  justify-content: right;
}

.customerColorExplanation {
  margin-top: 20px;
  width: 16%;
  margin-right: auto;
  white-space: nowrap;
}

.customerColumn {
  border-bottom: 1px solid black;
  width: 200px;
  margin-bottom: 10px;
}

.column-explanation > * {
  padding: 20px;
}

.salesColumnHeader {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.projectColumnHeader {
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.unhandledCases {
  justify-content: center;
  width: 90%;
  margin: auto;
  display: flex;
}

.horizontalTableIconsRow > * {
  text-align: center;
  width: 50%;
}

.customer-header-div {
  text-align: center;
  width: 200px;
}

.headerRow-emptyCell {
  width: 200px;
}